<template>
    <el-card shadow="never">
        <el-row>
            <el-button type="primary" @click="recyclableBoxData" :loading="loading" class="btn">导出在库数据</el-button>
        </el-row>
    </el-card>
</template>

<script>

    export default {
        name: "StockArticleReport",
        data() {
            return {
                loading: false
            }
        },
        methods: {
            recyclableBoxData() {
                this.loading = true;
                this.$axios.get('recyclableBox/stockArticleReport').then(response => {
                    this.loading = false;
                    let res = response.data;
                    if (res.code !== 0) {
                        return this.$message.error(res.message);
                    }
                    if (res.data.url) {
                        const link = document.createElement('a');
                        link.href = res.data.url;
                        document.body.appendChild(link);
                        link.click();
                        URL.revokeObjectURL(link.href);
                        document.body.removeChild(link);

                    }
                }, error => {
                    this.loading = false;
                    return this.$message.error('导出失败，' + error.message);
                });

            }
        }
    }
</script>

<style scoped>
    .btn {
        margin-left: 40px;
    }
</style>